
import {defineComponent} from "vue";
import {
    alertController,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonToolbar,
    modalController
} from "@ionic/vue";
import {arrowForwardOutline, checkmarkOutline, closeOutline} from "ionicons/icons";

export default defineComponent({
    name: "EditIncome",
    props: {
        input: {
            required: false,
            default: null
        }
    },
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon,
        IonItem,
        IonList,
        IonInput,
        IonLabel,
    },
    data(){
        return {
            arrowForwardOutline,
            checkmarkOutline,
            closeOutline,
            income: {
                name: '',
                description: '',
                amount: 0
            }
        }
    },
    methods: {
        async back(){
            if(this.income.amount !== 0 || this.income.name !== '' || this.income.description !== ''){
                const alert = await alertController.create({
                    message: 'לסגור ללא שמירת שינויים ?',
                    buttons: [
                        {
                            text: 'אישור',
                            role: 'confirm'
                        },
                        {
                            text: 'ביטול',
                            role: 'cancel'
                        }
                    ]
                })
                await alert.present()
                const {role} = await alert.onDidDismiss()
                if(role === 'confirm'){
                    await modalController.dismiss(null, 'back')
                }
            }else{
                await modalController.dismiss(null, 'back')
            }
        },
        save(){
            modalController.dismiss(this.income, 'save')
        }
    },
    beforeMount() {
        if(this.input){
            this.income = Object.assign(this.income, this.input)
        }
    }
})
