<template>
    <ion-page class="ion-page income-and-expenses">
        <ion-content>
            <ion-refresher slot="fixed" @ionRefresh="doRefresh">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>
            <div class="ei-title">הוצאות והכנסות</div>
            <ion-list>
                <ion-item>
                    <ion-label>סה"כ הכנסות</ion-label>
                    <ion-badge slot="end" color="secondary">{{formattedTotalIncome}} ₪</ion-badge>
                </ion-item>
                <ion-item>
                    <ion-label>סה"כ הוצאות</ion-label>
                    <ion-badge slot="end" color="secondary">{{formattedTotalExpenses}} ₪</ion-badge>
                </ion-item>
                <ion-item>
                    <ion-label>רווח</ion-label>
                    <ion-badge v-if="revenue > 0" slot="end" color="success">{{formattedRevenue}} ₪</ion-badge>
                    <ion-badge v-else slot="end" color="danger">{{formattedRevenue}} ₪</ion-badge>
                </ion-item>
            </ion-list>
            <ion-segment :value="currentSegment" @ionChange="segmentChanged">
                <ion-segment-button value="expenses">
                    <ion-label>הוצאות</ion-label>
                </ion-segment-button>
                <ion-segment-button value="incomes">
                    <ion-label>הכנסות</ion-label>
                </ion-segment-button>
            </ion-segment>
            <ion-list v-if="currentSegment === 'incomes'">
                <ion-item-sliding v-for="(income, incIn) in incomes" :key="incIn">
                    <ion-item-options side="start">
                        <ion-item-option color="danger" @click="removeIncome(income)">
                            <ion-icon :icon="trashOutline"></ion-icon>
                        </ion-item-option>
                    </ion-item-options>
                    <ion-item button @click="editIncome(income, incIn)">
                      <ion-label>
                          <h2>{{income.name}}</h2>
                          <h3>{{numberFormat(income.amount)}} ₪</h3>
                          <p>{{income.description}}</p>
                      </ion-label>
                    </ion-item>
                </ion-item-sliding>
                <div class="item bottom-placeholder"></div>
            </ion-list>
            <ion-list v-else>
                <ion-item-sliding v-for="(expanse, expIn) in expenses" :key="expIn">
                    <ion-item-options side="start">
                        <ion-item-option color="danger" @click="removeExpense(expanse)">
                            <ion-icon :icon="trashOutline"></ion-icon>
                        </ion-item-option>
                    </ion-item-options>
                    <ion-item button @click="editExpense(expanse, expIn)">
                      <ion-label>
                          <h2>{{expanse.name}}</h2>
                          <h3>{{numberFormat(expanse.amount)}} ₪</h3>
                          <p>{{expanse.description}}</p>
                      </ion-label>
                      <ion-note slot="end" v-if="expanse.paid">שולם <ion-icon color="success" :icon="checkmarkOutline"></ion-icon></ion-note>
                    </ion-item>
                </ion-item-sliding>
            </ion-list>
            <div class="fab-gap"></div>
            <ion-fab vertical="bottom" horizontal="start" slot="fixed">
                <ion-fab-button>
                    <ion-icon :icon="addOutline"></ion-icon>
                </ion-fab-button>
                <ion-fab-list side="end">
                    <ion-fab-button @click="editIncome()"><ion-icon :icon="logInOutline"></ion-icon></ion-fab-button>
                    <ion-fab-button @click="editExpense()"><ion-icon :icon="logOutOutline"></ion-icon></ion-fab-button>
                </ion-fab-list>
            </ion-fab>
        </ion-content>
    </ion-page>
</template>

<script>
import {
  alertController,
  IonBadge,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonNote,
  IonPage, IonRefresher, IonRefresherContent,
  IonSegment, IonSegmentButton,
  modalController, toastController
} from "@ionic/vue";
import {addOutline, checkmarkOutline, logInOutline, logOutOutline, pencilOutline, trashOutline} from "ionicons/icons";
import {defineComponent} from "vue";
import EditIncome from "@/modals/EditIncome.vue";
import EditExpense from "@/modals/EditExpense.vue";
import numeral from 'numeral'
import {mapGetters} from "vuex";

export default defineComponent({
    name: "IncomeAndExpenses",
    components: {
        IonPage,
        IonContent,
        IonFab,
        IonFabButton,
        IonFabList,
        IonIcon,
        IonSegment,
        IonSegmentButton,
        IonList,
        IonItem,
        IonLabel,
        IonNote,
        IonBadge,
        IonRefresher,
        IonRefresherContent,
        IonItemOptions,
        IonItemOption,
        IonItemSliding
    },
    computed:{
        revenue(){
            return this.incomesTotal - this.expensesTotal
        },
        formattedRevenue(){
            return this.numberFormat(this.revenue)
        },
        formattedTotalIncome(){
            return this.numberFormat(this.incomesTotal)
        },
        formattedTotalExpenses(){
            return this.numberFormat(this.expensesTotal)
        },
        ...mapGetters(['incomes', 'expenses','expensesTotal','incomesTotal'])
    },
    data(){
        return {
            logInOutline,
            logOutOutline,
            addOutline,
            checkmarkOutline,
            pencilOutline,
            trashOutline,
            currentSegment: 'expenses'
        }
    },
    methods:{
        numberFormat(number){
            return numeral(number).format('0,0')
        },
        segmentChanged(e){
            this.currentSegment = e.detail.value
        },
        async editIncome(income = null){
            const modal = await modalController.create({
                component: EditIncome,
                componentProps: {
                    input: income
                }
            })

            await modal.present()
            const {data, role} = await modal.onDidDismiss()
            if(role === 'save'){
                this.$store.dispatch('incomeSave', data)
            }
        },
        async editExpense(expense = null){
            const modal = await modalController.create({
                component: EditExpense,
                componentProps: {
                    input: expense
                }
            })

            await modal.present()
            const {data, role} = await modal.onDidDismiss()
            if(role === 'save') {
                await this.$store.dispatch('expenseSave', data)
            }
        },
        async removeIncome(income){
          const confirm = await alertController.create({
            message: 'למחוק את ההכנסה ?',
            buttons: [
              {
                text: 'אישור',
                role: 'confirm'
              },
              {
                text: 'ביטול',
                role: 'cancel'
              }
            ]
          })
          await confirm.present()
          const {role} = await confirm.onDidDismiss()
          if(role === 'confirm'){
            if(await this.$store.dispatch('incomeRemove', income)){
              await (await toastController.create({
                message: 'הכנסה נמחקה בהצלחה',
                duration: 1000,
                color: "primary"
              })).present()
              await this.loadData()
            }else{
              await (await toastController.create({
                message: 'מחיקת הכנסה נכשלה.',
                duration: 1000,
                color: "secondary"
              })).present()
            }
          }
        },
        async removeExpense(expense){
          const confirm = await alertController.create({
            message: 'למחוק את ההוצאה ?',
            buttons: [
              {
                text: 'אישור',
                role: 'confirm'
              },
              {
                text: 'ביטול',
                role: 'cancel'
              }
            ]
          })
          await confirm.present()
          const {role} = await confirm.onDidDismiss()
          if(role === 'confirm'){
            if(await this.$store.dispatch('expenseRemove', expense)){
              await (await toastController.create({
                message: 'הוצאה נמחקה בהצלחה',
                duration: 1000,
                color: "primary"
              })).present()
              await this.loadData()
            }else{
              await (await toastController.create({
                message: 'מחיקת הוצאה נכשלה.',
                duration: 1000,
                color: "secondary"
              })).present()
            }
          }
        },
        async loadData(){
            await this.$store.dispatch('expensesLoad')
            await this.$store.dispatch('incomesLoad')
        },
        async doRefresh(e){
            await this.loadData()
            e.target.complete()
        }
    },
    async mounted() {
      await this.loadData()
    }
})
</script>

<style scoped>

</style>
